import { ListChatbotTableStructureDto } from 'form/listing/chatbot-table-structure';
import helper from 'helpers/apiRequest';
import { useEffect, useState } from 'react';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import {
  Button,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Offcanvas,
  Toast
} from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { useBootstrapPrefix } from 'react-bootstrap/esm/ThemeProvider';
import { useToast } from 'providers/ToastProvider';
import { ListInformationBaseDto } from 'form/listing/information-base';
import { CreateInformationBaseDto } from 'form/information-base/create.dto';

const ListInformationBaseStructure = () => {
  let client_id: string;
  const user_info = localStorage.getItem('user_info');
  if (user_info) {
    const data = JSON.parse(user_info);
    client_id = data.client_id;
  }
  const { addToast } = useToast();

  //View, Delete, and Update
  const [selectedRowData, setSelectedRowData] =
    useState<ListInformationBaseDto | null>(null);

  //Delete
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const handleCancelDeleteRequest = () => {
    setSelectedRowData(null);
    setShowDeleteConfirmationModal(false);
  };
  const handleRemoveAction = (rowData: ListInformationBaseDto) => {
    setSelectedRowData(rowData);
    setShowDeleteConfirmationModal(true);
  };
  const handleConfirmDeleteRequest = async () => {
    console.log('handleConfirmDeleteRequest', selectedRowData);
    if (selectedRowData) {
      const result = await helper.deleteRequest(
        `/client-information-base/${selectedRowData.id}`,
        {}
      ); // Adjust endpoint as needed
      if (result.message == 'remove_client_information_base_successfully') {
        setDataSource(prevDataSource =>
          prevDataSource.filter(row => row.id !== selectedRowData.id)
        );
        setShowDeleteConfirmationModal(false);
      }
    }
  };

  //View
  const [showViewOffcanvas, setShowViewOffcanvas] = useState(false);
  const handleViewOffcanvasClose = () => setShowViewOffcanvas(false);
  const handleViewAction = (rowData: ListInformationBaseDto) => {
    setSelectedRowData(rowData);
    //console.log("handleViewAction",rowData)
    setShowViewOffcanvas(true);
  };

  //update
  const [showUpdateOffcanvas, setShowUpdateOffcanvas] = useState(false);
  const [showUpdateToast, setUpdateShowToast] = useState(false);

  const handleUpdateOffcanvasClose = () => setShowUpdateOffcanvas(false);
  const handleUpdateAction = (rowData: ListInformationBaseDto) => {
    setSelectedRowData(rowData);
    setFormData(rowData);
    setShowUpdateOffcanvas(true);
  };
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<CreateInformationBaseDto>({
    client_id: 0,
    question: '',
    answer: ''
  });

  const handleUpdateSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    console.log(form);
    const result = await helper.updateRequest(
      `/client-information-base/${selectedRowData?.id}`,
      formData
    ); // Adjust endpoint as needed
    if (result.message == 'update_client_information_base_successfully') {
      addToast(
        'Update successfully',
        'Update information base successfully',
        'success'
      );
      fetchData();
    } else {
      addToast(
        'Update failure',
        'The attempt to update the information base was unsuccessful. ',
        'error'
      );
    }
    setShowUpdateOffcanvas(false);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  //List
  const [dataSource, setDataSource] = useState<ListChatbotTableStructureDto[]>(
    []
  );
  const columns = [
    {
      header: 'Question',
      accessorKey: 'question',
      meta: {
        headerProps: { style: { maxWidth: '80%' } }
      }
    },
    {
      id: 'action',
      header: 'Action',
      cell: ({ row }: any) => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item
              eventKey="1"
              onClick={() => {
                handleViewAction(row.original);
              }}
            >
              View
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => {
                handleUpdateAction(row.original);
              }}
            >
              Update
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              className="text-danger"
              onClick={() => {
                handleRemoveAction(row.original);
              }}
            >
              Remove
            </Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  //list
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await helper.getRequest('/client-information-base', {
      client_id: client_id
    }); // Adjust endpoint as needed
    setDataSource(result.data);
  };

  const table = useAdvanceTable({
    data: dataSource,
    columns: columns,
    pageSize: 10,
    pagination: true,
    selection: false,
    sortable: true
  });

  return (
    <>
      <div className="text-right mb-7">
        <h3 className="text-body-highlight">List Information Base</h3>
      </div>
      <AdvanceTableProvider {...table}>
        {/* <SearchBox
                    placeholder="Search..."
                    size="sm"
                    onChange={handleSearchInputChange}
                    className="mx-auto mb-4"
                /> */}
        <AdvanceTable
          tableProps={{
            size: 'sm',
            className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
          }}
          rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
        />
        <AdvanceTableFooter navBtn />
      </AdvanceTableProvider>

      <Modal
        show={showDeleteConfirmationModal}
        onHide={handleCancelDeleteRequest}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to remove this data?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDeleteRequest}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmDeleteRequest}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/** VIEW */}
      <Offcanvas
        show={showViewOffcanvas}
        onHide={handleViewOffcanvasClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>View Question and Answer:</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form noValidate method="POST">
            <Form.Group className="mb-3 text-start" controlId="answer">
              <Form.Label>Answer</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Answer"
                  name="answer"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.answer}
                  disabled
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="question">
              <Form.Label>Question</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Column Info"
                  name="question"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.question}
                  disabled
                  required
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showUpdateOffcanvas}
        onHide={handleUpdateOffcanvasClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Update Question and Answer</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleUpdateSubmit}
            method="POST"
          >
            <Form.Group className="mb-3 text-start" controlId="question">
              <Form.Label>Question</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Question"
                  name="question"
                  aria-describedby="inputGroupPrepend"
                  value={formData.question}
                  onChange={handleChange}
                  required
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="answer">
              <Form.Label>Question</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Answer"
                  name="answer"
                  aria-describedby="inputGroupPrepend"
                  value={formData.answer}
                  onChange={handleChange}
                  required
                />
              </InputGroup>
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ListInformationBaseStructure;
