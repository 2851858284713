import helper from 'helpers/apiRequest';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

const Profile = () => {
  interface Profile {
    id: number;
    username: string;
    password: string;
    created_at: string;
    updated_at: string;
  }
  const [profile, setProfile] = useState<Profile | null>(null);
  const fetchData = async () => {
    const result = await helper.getRequest('/user/get_profile_info', {}); // Adjust endpoint as needed
    setProfile(result.data);
  };

  //list
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container>
      <h2 className="mt-4">Profile</h2>
      <Card className="mt-4">
        <Card.Body>
          <Row>
            <Col md={6}>
              <strong>Username</strong>
            </Col>
            <Col md={6}>{profile?.username}</Col>
          </Row>
          <Row>
            <Col md={6}>
              <strong>Created at</strong>
            </Col>
            <Col md={6}>{profile?.created_at}</Col>
          </Row>
          <Row>
            <Col md={6}>
              <strong>Updated at</strong>
            </Col>
            <Col md={6}>{profile?.updated_at}</Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Profile;
