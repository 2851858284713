// apiHelper.js
import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json'
    // Add any other custom headers if needed
  }
});

// Add a request interceptor to include the token in headers
apiClient.interceptors.request.use(
  config => {
    const user_info = localStorage.getItem('user_info');
    if (user_info) {
      const data = JSON.parse(user_info);
      config.headers['Authorization'] = `Bearer ${data.accessToken}`;
    }
    return config;
  },
  error => {
    console.log('apiClient.interceptors.request error');
    return Promise.reject(error);
  }
);

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const postRequest = async (endpoint: string, data: any) => {
  try {
    const response = await apiClient.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error making POST request', error);
  }
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRequest = async (endpoint: string, params: any) => {
  try {
    const response = await apiClient.get(endpoint, {
      params: {
        ...params
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error making POST request', error);
  }
};
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const deleteRequest = async (endpoint: string, params: any) => {
  try {
    const response = await apiClient.delete(endpoint, params);
    return response.data;
  } catch (error) {
    console.error('Error making POST request', error);
  }
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateRequest = async (endpoint: string, params: any) => {
  try {
    const response = await apiClient.patch(endpoint, params);
    return response.data;
  } catch (error) {
    console.error('Error making POST request', error);
  }
};

// Export as an object
const helper = {
  postRequest,
  getRequest,
  deleteRequest,
  updateRequest
};

export default helper;
