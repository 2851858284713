import { UilTimes } from '@iconscout/react-unicons';
import { CreateChatbotTableStructureDto } from 'form/chatbot-table-structure/create.dto';
import helper from 'helpers/apiRequest';
import { useState } from 'react';
import { Button, Form, InputGroup, Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CreateChatbotTableStructure = () => {
  const navigate = useNavigate();

  const [showToast, setShowToast] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<CreateChatbotTableStructureDto>({
    database_name: '',
    table_name: '',
    default_column_name: '',
    column_info: ''
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    console.log(form);
    const result = await helper.postRequest(
      '/chatbot-table-structure',
      formData
    ); // Adjust endpoint as needed
    if (result.message == 'create_chatbot_table_structure_successfully') {
      navigate('/dashboard/chatbot-table-structure/list');
    } else {
      setShowToast(true);
      setFormData({
        database_name: '',
        table_name: '',
        default_column_name: '',
        column_info: ''
      });
    }
    //logic for submit creating table structure info
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <>
      <div className="text-right mb-7">
        <h3 className="text-body-highlight">
          Create Table structure for Chatbot
        </h3>
      </div>

      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        method="POST"
      >
        <Form.Group className="mb-3 text-start" controlId="databaseName">
          <Form.Label>Database Name</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              placeholder="Database Name"
              name="database_name"
              aria-describedby="inputGroupPrepend"
              value={formData.database_name}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please fill in the database field.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3 text-start" controlId="tableName">
          <Form.Label>Table Name</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              placeholder="Table Name"
              name="table_name"
              aria-describedby="inputGroupPrepend"
              value={formData.table_name}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please fill in table field.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3 text-start" controlId="defaultColumnName">
          <Form.Label>Default Column Name</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Default column name"
              name="default_column_name"
              aria-describedby="inputGroupPrepend"
              value={formData.default_column_name}
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3 text-start" controlId="columnInfo">
          <Form.Label>Column Info</Form.Label>
          <InputGroup>
            <Form.Control
              as="textarea"
              placeholder="Column Info"
              name="column_info"
              aria-describedby="inputGroupPrepend"
              value={formData.column_info}
              onChange={handleChange}
              required
            />
          </InputGroup>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <div className="position-fixed center-0 center-0 p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <Button className="ms-2 p-0 " onClick={() => setShowToast(false)}>
              <UilTimes className="fs-7" />
            </Button>
          </Toast.Header>
          <Toast.Body>
            Failed to create the chatbot table structure data in Database
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default CreateChatbotTableStructure;
