import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Form as BootstrapForm, Button, Modal } from 'react-bootstrap';
import helper from 'helpers/apiRequest';
import { useNavigate } from 'react-router-dom';

const CreateClient = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isFrozen, setIsFrozen] = useState(false);

  interface FormValues {
    client_name: string;
    client_company_name: string;
    description: string;
  }

  // Define the initial values for the form
  const initialValues = {
    client_name: '',
    client_company_name: '',
    description: ''
  };

  // Define the validation schema using Yup
  const validationSchema = Yup.object({
    client_name: Yup.string().required('Client Name is required'),
    description: Yup.string().required('Description is required'),
    client_company_name: Yup.string().required(
      'Client Company Name is required'
    )
  });

  // Define the onSubmit function
  const onSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>
  ) => {
    const result = await helper.postRequest('/client', values);
    //! add create user api implementation
    setSubmitting(false);
    if (result.message === 'create_client_successfully') {
      createAndDownloadFile(values, result?.data?.secret_key);
      setShowModal(true);
      setIsFrozen(true);
      setTimeout(() => {
        navigate('/dashboard/client/list'); // Replace '/some-path' with the desired path
      }, 1000);
    } else {
      setFieldError('client_name', result.message);
    }
  };

  const createAndDownloadFile = (values: FormValues, secret_key: string) => {
    const text = `Client Name: ${values.client_name}\nClient Company Name: ${values.client_company_name}\nDescription: ${values.description}\nSecret Key: ${secret_key}`;
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'client_info.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsFrozen(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <BootstrapForm.Group className="mb-3" controlId="client_name">
              <BootstrapForm.Label>Client Name</BootstrapForm.Label>
              <Field
                type="text"
                name="client_name"
                as={BootstrapForm.Control}
                disabled={isFrozen}
              />
              <ErrorMessage
                name="client_name"
                component="div"
                className="text-danger"
              />
            </BootstrapForm.Group>

            <BootstrapForm.Group
              className="mb-3"
              controlId="client_company_name"
            >
              <BootstrapForm.Label>Client Company Name</BootstrapForm.Label>
              <Field
                type="text"
                name="client_company_name"
                as={BootstrapForm.Control}
                disabled={isFrozen}
              />
              <ErrorMessage
                name="client_company_name"
                component="div"
                className="text-danger"
              />
            </BootstrapForm.Group>

            <BootstrapForm.Group className="mb-3" controlId="description">
              <BootstrapForm.Label>Description</BootstrapForm.Label>
              <Field
                type="text"
                name="description"
                as={BootstrapForm.Control}
                disabled={isFrozen}
              />
              <ErrorMessage
                name="description"
                component="div"
                className="text-danger"
              />
            </BootstrapForm.Group>

            <Button type="submit" disabled={isSubmitting || isFrozen}>
              Create Client
            </Button>
          </Form>
        )}
      </Formik>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Client Created</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The client information has been saved successfully.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateClient;
