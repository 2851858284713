import helper from 'helpers/apiRequest';
import { useEffect, useState } from 'react';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import {
  Button,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Offcanvas
} from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { useToast } from 'providers/ToastProvider';
import { ListClientDto } from 'form/listing/client';

const ListClient = () => {
  const { addToast } = useToast();

  //View, Delete, and Update
  const [selectedRowData, setSelectedRowData] = useState<ListClientDto | null>(
    null
  );

  //Delete
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const handleCancelDeleteRequest = () => {
    setSelectedRowData(null);
    setShowDeleteConfirmationModal(false);
  };
  const handleRemoveAction = (rowData: ListClientDto) => {
    setSelectedRowData(rowData);
    setShowDeleteConfirmationModal(true);
  };
  const handleConfirmDeleteRequest = async () => {
    console.log('handleConfirmDeleteRequest', selectedRowData);
    if (selectedRowData) {
      const result = await helper.deleteRequest(
        `/client/${selectedRowData.id}`,
        {}
      ); // Adjust endpoint as needed
      if (result.message == 'remove_client_successfully') {
        setDataSource(prevDataSource =>
          prevDataSource.filter(row => row.id !== selectedRowData.id)
        );
        setShowDeleteConfirmationModal(false);
      }
    }
  };

  //View
  const [showViewOffcanvas, setShowViewOffcanvas] = useState(false);
  const handleViewOffcanvasClose = () => setShowViewOffcanvas(false);
  const handleViewAction = (rowData: ListClientDto) => {
    setSelectedRowData(rowData);
    console.log('handleViewAction', rowData);
    setShowViewOffcanvas(true);
  };

  //List
  const [dataSource, setDataSource] = useState<ListClientDto[]>([]);
  const columns = [
    {
      accessorKey: 'client_name',
      header: 'Client Name'
    },
    {
      accessorKey: 'client_company_name',
      header: 'Client Company Name'
    },
    {
      accessorKey: 'description',
      header: 'Description'
    },
    {
      id: 'action',
      header: 'Action',
      cell: ({ row }: any) => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item
              eventKey="1"
              onClick={() => {
                handleViewAction(row.original);
              }}
            >
              View
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              className="text-danger"
              onClick={() => {
                handleRemoveAction(row.original);
              }}
            >
              Remove
            </Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  //list
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await helper.getRequest('/client', {}); // Adjust endpoint as needed
    setDataSource(result.data);
  };

  const table = useAdvanceTable({
    data: dataSource,
    columns: columns,
    pageSize: 10,
    pagination: true,
    selection: false,
    sortable: true
  });

  return (
    <>
      <div className="text-right mb-7">
        <h3 className="text-body-highlight">List Client</h3>
      </div>
      <AdvanceTableProvider {...table}>
        {/* <SearchBox
                    placeholder="Search..."
                    size="sm"
                    onChange={handleSearchInputChange}
                    className="mx-auto mb-4"
                /> */}
        <AdvanceTable
          tableProps={{
            size: 'sm',
            className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
          }}
          rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
        />
        <AdvanceTableFooter navBtn />
      </AdvanceTableProvider>

      <Modal
        show={showDeleteConfirmationModal}
        onHide={handleCancelDeleteRequest}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to remove this data?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDeleteRequest}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmDeleteRequest}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Offcanvas
        show={showViewOffcanvas}
        onHide={handleViewOffcanvasClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>View Client information:</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form noValidate method="POST">
            <Form.Group className="mb-3 text-start" controlId="client_name">
              <Form.Label>Client Name</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="client_name"
                  name="client_name"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.client_name}
                  disabled
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group
              className="mb-3 text-start"
              controlId="client_company_name"
            >
              <Form.Label>Client Company Name</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="client_company_name"
                  name="client_company_name"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.client_company_name}
                  disabled
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="description">
              <Form.Label>Description</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="description"
                  name="description"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.description}
                  disabled
                  required
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ListClient;
