import { ListChatbotTableStructureDto } from 'form/listing/chatbot-table-structure';
import helper from 'helpers/apiRequest';
import { useEffect, useState } from 'react';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import {
  Button,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Offcanvas
} from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { CreateChatbotTableStructureDto } from 'form/chatbot-table-structure/create.dto';
import { useToast } from 'providers/ToastProvider';

const ListChatbotTableStructure = () => {
  const { addToast } = useToast();

  //View, Delete, and Update
  const [selectedRowData, setSelectedRowData] =
    useState<ListChatbotTableStructureDto | null>(null);

  //Delete
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const handleCancelDeleteRequest = () => {
    setSelectedRowData(null);
    setShowDeleteConfirmationModal(false);
  };
  const handleRemoveAction = (rowData: ListChatbotTableStructureDto) => {
    setSelectedRowData(rowData);
    setShowDeleteConfirmationModal(true);
  };
  const handleConfirmDeleteRequest = async () => {
    console.log('handleConfirmDeleteRequest', selectedRowData);
    if (selectedRowData) {
      const result = await helper.deleteRequest(
        `/chatbot-table-structure/${selectedRowData.id}`,
        {}
      ); // Adjust endpoint as needed
      if (result.message == 'remove_chatbot_table_structure_successfully') {
        setDataSource(prevDataSource =>
          prevDataSource.filter(row => row !== selectedRowData)
        );
        setShowDeleteConfirmationModal(false);
      }
    }
  };

  //View
  const [showViewOffcanvas, setShowViewOffcanvas] = useState(false);
  const handleViewOffcanvasClose = () => setShowViewOffcanvas(false);
  const handleViewAction = (rowData: ListChatbotTableStructureDto) => {
    setSelectedRowData(rowData);
    //console.log("handleViewAction",rowData)
    setShowViewOffcanvas(true);
  };

  //update
  const [showUpdateOffcanvas, setShowUpdateOffcanvas] = useState(false);

  const handleUpdateOffcanvasClose = () => setShowUpdateOffcanvas(false);
  const handleUpdateAction = (rowData: ListChatbotTableStructureDto) => {
    setSelectedRowData(rowData);
    setFormData(rowData);
    setShowUpdateOffcanvas(true);
  };
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<CreateChatbotTableStructureDto>({
    database_name: '',
    table_name: '',
    default_column_name: '',
    column_info: ''
  });

  const handleUpdateSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    console.log(form);
    const result = await helper.updateRequest(
      `/chatbot-table-structure/${selectedRowData?.id}`,
      formData
    ); // Adjust endpoint as needed
    if (result.message == 'update_chatbot_table_structure_successfully') {
      addToast(
        'Update successfully',
        'Update chatbot table structure successfully',
        'success'
      );
      fetchData();
    } else {
      addToast(
        'Update failure',
        'The attempt to update the chatbot table structure was unsuccessful. ',
        'error'
      );
    }
    setShowUpdateOffcanvas(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  //List
  const [dataSource, setDataSource] = useState<ListChatbotTableStructureDto[]>(
    []
  );
  const columns = [
    {
      accessorKey: 'database_name',
      header: 'Database Name'
    },
    {
      accessorKey: 'table_name',
      header: 'Table Name'
    },
    {
      accessorKey: 'default_column_name',
      header: 'Default Column Name'
    },
    {
      id: 'action',
      header: 'Action',
      cell: ({ row }: any) => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item
              eventKey="1"
              onClick={() => {
                handleViewAction(row.original);
              }}
            >
              View
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => {
                handleUpdateAction(row.original);
              }}
            >
              Update
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              className="text-danger"
              onClick={() => {
                handleRemoveAction(row.original);
              }}
            >
              Remove
            </Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  //list
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await helper.getRequest('/chatbot-table-structure', {}); // Adjust endpoint as needed
    setDataSource(result.data);
  };

  const table = useAdvanceTable({
    data: dataSource,
    columns: columns,
    pageSize: 10,
    pagination: true,
    selection: false,
    sortable: true
  });

  return (
    <>
      <div className="text-right mb-7">
        <h3 className="text-body-highlight">
          List Table structure for Chatbot
        </h3>
      </div>
      <AdvanceTableProvider {...table}>
        {/* <SearchBox
                    placeholder="Search..."
                    size="sm"
                    onChange={handleSearchInputChange}
                    className="mx-auto mb-4"
                /> */}
        <AdvanceTable
          tableProps={{
            size: 'sm',
            className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
          }}
          rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
        />
        <AdvanceTableFooter navBtn />
      </AdvanceTableProvider>

      <Modal
        show={showDeleteConfirmationModal}
        onHide={handleCancelDeleteRequest}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to remove this data?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDeleteRequest}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmDeleteRequest}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/** VIEW */}
      <Offcanvas
        show={showViewOffcanvas}
        onHide={handleViewOffcanvasClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            View Table Name: {selectedRowData?.table_name}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form noValidate method="POST">
            <Form.Group className="mb-3 text-start" controlId="databaseName">
              <Form.Label>Database Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Database Name"
                  name="database_name"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.database_name}
                  disabled
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="tableName">
              <Form.Label>Table Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Table Name"
                  name="table_name"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.table_name}
                  disabled
                />
              </InputGroup>
            </Form.Group>

            <Form.Group
              className="mb-3 text-start"
              controlId="defaultColumnName"
            >
              <Form.Label>Default Column Name</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Default column name"
                  name="default_column_name"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.default_column_name}
                  disabled
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="columnInfo">
              <Form.Label>Column Info</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Column Info"
                  name="column_info"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.column_info}
                  disabled
                  required
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showUpdateOffcanvas}
        onHide={handleUpdateOffcanvasClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Update Table Name: {selectedRowData?.table_name}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleUpdateSubmit}
            method="POST"
          >
            <Form.Group className="mb-3 text-start" controlId="databaseName">
              <Form.Label>Database Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Database Name"
                  name="database_name"
                  aria-describedby="inputGroupPrepend"
                  value={formData.database_name}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please fill in the database field.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="tableName">
              <Form.Label>Table Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Table Name"
                  name="table_name"
                  aria-describedby="inputGroupPrepend"
                  value={formData.table_name}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please fill in table field.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group
              className="mb-3 text-start"
              controlId="defaultColumnName"
            >
              <Form.Label>Default Column Name</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Default column name"
                  name="default_column_name"
                  aria-describedby="inputGroupPrepend"
                  value={formData.default_column_name}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="columnInfo">
              <Form.Label>Column Info</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Column Info"
                  name="column_info"
                  aria-describedby="inputGroupPrepend"
                  value={formData.column_info}
                  onChange={handleChange}
                  required
                />
              </InputGroup>
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ListChatbotTableStructure;
