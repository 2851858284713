import React from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Alert, Form as BootstrapForm, Button } from 'react-bootstrap';
import helper from 'helpers/apiRequest';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ChangePasswordForm = () => {
  const navigate = useNavigate();

  interface FormValues {
    old_password: string;
    new_password: string;
    confirm_password: string;
  }
  // Define the initial values for the form
  const initialValues = {
    old_password: '',
    new_password: '',
    confirm_password: ''
  };

  // Define the validation schema using Yup
  const validationSchema = Yup.object({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string()
      .min(6, 'New Password must be at least 6 characters')
      .required('New Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), ''], 'Passwords must match')
      .required('Confirm Password is required')
  });

  // Define the onSubmit function
  // Define the onSubmit function
  const onSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>
  ) => {
    const result = await helper.updateRequest('/user/change-password', values);
    if (result.message == 'update_user_password_successfully') {
      navigate('/user/profile');
    } else {
      setFieldError('old_password', result.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <BootstrapForm.Group className="mb-3" controlId="old_password">
            <BootstrapForm.Label>Old Password</BootstrapForm.Label>
            <Field
              type="password"
              name="old_password"
              as={BootstrapForm.Control}
            />
            <ErrorMessage
              name="old_password"
              component="div"
              className="text-danger"
            />
          </BootstrapForm.Group>

          <BootstrapForm.Group className="mb-3" controlId="new_password">
            <BootstrapForm.Label>New Password</BootstrapForm.Label>
            <Field
              type="password"
              name="new_password"
              as={BootstrapForm.Control}
            />
            <ErrorMessage
              name="new_password"
              component="div"
              className="text-danger"
            />
          </BootstrapForm.Group>

          <BootstrapForm.Group className="mb-3" controlId="confirm_password">
            <BootstrapForm.Label>Confirm New Password</BootstrapForm.Label>
            <Field
              type="password"
              name="confirm_password"
              as={BootstrapForm.Control}
            />
            <ErrorMessage
              name="confirm_password"
              component="div"
              className="text-danger"
            />
          </BootstrapForm.Group>

          <Button type="submit" disabled={isSubmitting}>
            Change Password
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
