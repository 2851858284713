import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode
} from 'react';
import {
  Alert,
  Toast as BootstrapToast,
  ToastContainer as BootstrapToastContainer
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

type Toast = {
  id: number;
  title: string;
  message: string;
  bg: string;
};

type ToastContextType = {
  addToast: (title: string, message: string, bg?: string) => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

type ToastProviderProps = {
  children: ReactNode;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    (title: string, message: string, bg: string = 'info') => {
      const id = Date.now();
      setToasts(prevToasts => [...prevToasts, { id, title, message, bg }]);
      setTimeout(() => {
        setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
      }, 3000);
    },
    []
  );

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <BootstrapToastContainer position="middle-center" className="p-3">
        {toasts.map(toast => (
          <Alert key={toast.id} variant={toast.bg}>
            {toast.message}
          </Alert>
        ))}
      </BootstrapToastContainer>
    </ToastContext.Provider>
  );
};
