import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routesClient: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Chatbot Table Structure',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'list',
            path: '/dashboard/chatbot-table-structure/list',
            pathName: 'chatbox-table-structure-list',
            active: true
          },
          {
            name: 'create new',
            path: '/dashboard/chatbot-table-structure/create',
            pathName: 'chatbox-table-structure-create-new',
            active: true
          }
        ]
      },
      {
        name: 'Information Base',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'list',
            path: '/dashboard/information-base/list',
            pathName: 'information-base-list',
            active: true
          },
          {
            name: 'create new',
            path: '/dashboard/information-base/create',
            pathName: 'information-base-create-new',
            active: true
          }
        ]
      }
    ]
  }
];

export const routesAdmin: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Client',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'list',
            path: '/dashboard/client/list',
            pathName: 'client-list',
            active: true
          },
          {
            name: 'create new',
            path: '/dashboard/client/create',
            pathName: 'client-create-new',
            active: true
          }
        ]
      },
      {
        name: 'Client Chat Session',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'list',
            path: '/dashboard/client-chat-session/list',
            pathName: 'client-list',
            active: true
          }
        ]
      }
    ]
  }
];

export const routes: RouteItems[] = [];

export function getMenu(role: string) {
  switch (role) {
    case 'admin':
      return routesAdmin;
    case 'client':
      return routesClient;
    default:
      return [];
  }
}
