import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const useAuthenticate = (): boolean => {
  try {
    const user_info = localStorage.getItem('user_info');
    if (user_info) {
      const data = JSON.parse(user_info);
      const { exp } = jwtDecode(data.accessToken);
      if (!exp) {
        return false;
      }
      if (Date.now() <= exp * 1000) {
        return true;
      }
    } else {
      console.log('user_info else');
      return false;
    }
  } catch (error) {
    return false;
  }

  return false;
};
export function AuthRequiredProvider(): ReactElement {
  const isAuthenticated = useAuthenticate();

  if (!isAuthenticated) {
    console.log('!isAuthenticated');
    return <Navigate to={'/login'} />;
  }
  return <></>;
}
