import Button from 'components/base/Button';
import { UserLoginDto } from 'form/authentication/login.dto';
import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import helper from 'helpers/apiRequest';
const SignInForm = () => {
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<UserLoginDto>({
    username: '',
    password: ''
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    const result = await helper.postRequest('/auth/login', formData); // Adjust endpoint as needed

    if (result.message == 'login_success') {
      localStorage.setItem('user_info', JSON.stringify(result.data));
      if (result.data.client_id) {
        navigate('/dashboard/chatbot-table-structure/create');
      } else {
        navigate('/dashboard/client/list');
      }
    } else {
      setError('Failed to login');
      setFormData({
        username: '',
        password: ''
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Sign In</h3>
        <p className="text-body-tertiary">Get access to your account</p>
      </div>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        method="POST"
      >
        <Form.Group className="mb-3 text-start" controlId="username">
          <Form.Label>Username</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              placeholder="Username"
              name="username"
              aria-describedby="inputGroupPrepend"
              value={formData.username}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please fill in username field.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3 text-start" controlId="password">
          <Form.Label>Password</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              aria-describedby="inputGroupPrepend"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please fill in password field.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        {/* <Row className="flex-between-center mb-7">
          <Col xs="auto">
            <Form.Check type="checkbox" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                defaultChecked
              />
              <Form.Check.Label htmlFor="remember-me" className="mb-0">
                Remember me
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col xs="auto">
            <Link
              to={`/pages/authentication/${layout}/forgot-password`}
              className="fs-9 fw-semibold"
            >
              Forgot Password?
            </Link>
          </Col>
        </Row> */}
        <Button type="submit" variant="primary" className="w-100 mb-3">
          Sign In
        </Button>
        {error && (
          <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>
        )}
      </Form>
    </>
  );
};

export default SignInForm;
