import helper from 'helpers/apiRequest';
import { useState } from 'react';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Offcanvas,
  Row
} from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { ListClientChatSessionDto } from 'form/listing/client-chat-session';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

interface filters {
  trained_status: string;
}
interface updateTrainedStatusInput {
  client_chat_session_id: number;
  trained_status: string;
  trained_chatbot_answer: string;
}

const fetchData = async (filters: filters) => {
  const result = await helper.getRequest('/client-chat-session', filters); // Adjust endpoint as needed
  return result.data;
};

const updateTrainedStatus = async ({
  client_chat_session_id,
  trained_status,
  trained_chatbot_answer
}: updateTrainedStatusInput) => {
  const result = await helper.updateRequest(
    `/client-chat-session/trainedStatus/${client_chat_session_id}`,
    {
      trained_status: trained_status,
      trained_chatbot_answer: trained_chatbot_answer
    }
  ); // Adjust endpoint as needed
  return result.data;
};

const ListClientChatSession = () => {
  const queryClient = useQueryClient();

  // Define states for filters (e.g., search term, status filter)
  const [trainedStatus, setTrainedStatus] = useState('pending');

  //View,and Update
  const [selectedRowData, setSelectedRowData] =
    useState<ListClientChatSessionDto | null>(null);

  //View
  const [showViewOffcanvas, setShowViewOffcanvas] = useState(false);
  const handleViewOffcanvasClose = () => setShowViewOffcanvas(false);
  const handleViewAction = (rowData: ListClientChatSessionDto) => {
    setSelectedRowData(rowData);
    //console.log("handleViewAction",rowData)
    setShowViewOffcanvas(true);
  };

  //update
  const [showUpdateOffcanvas, setShowUpdateOffcanvas] = useState(false);

  const handleUpdateOffcanvasClose = () => setShowUpdateOffcanvas(false);
  const handleUpdateAction = (rowData: ListClientChatSessionDto) => {
    setFormData({
      trained_chatbot_answer: '',
      trained_status: '',
      client_chat_session_id: Number(rowData.id)
    });
    setShowUpdateOffcanvas(true);
  };
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<updateTrainedStatusInput>({
    client_chat_session_id: 0,
    trained_status: '',
    trained_chatbot_answer: ''
  });

  const handleUpdateSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    console.log(form);
    handleUpdate(
      formData.client_chat_session_id,
      formData.trained_status,
      formData.trained_chatbot_answer
    );
    setShowUpdateOffcanvas(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  const columns: ColumnDef<ListClientChatSessionDto>[] = [
    {
      accessorKey: 'client_company_name',
      header: 'Client Company Name'
    },
    {
      accessorKey: 'session_title',
      header: 'Session Title'
    },
    // {
    //     accessorKey: 'chatbot_msg',
    //     header:"Chatbot Message"
    // },
    {
      accessorKey: 'client_msg',
      header: 'Client Message'
    },
    {
      accessorKey: 'trained_status',
      header: 'Trained Status'
    },
    {
      id: 'action',
      header: 'Action',
      cell: ({ row }: any) => {
        if (row.original.trained_status == 'pending') {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    handleViewAction(row.original);
                  }}
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => {
                    handleUpdateAction(row.original);
                  }}
                >
                  Update
                </Dropdown.Item>
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        }
        if (row.original.trained_status == 'on_training') {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    handleViewAction(row.original);
                  }}
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => {
                    handleUpdate(row.original.id, 'trained', '');
                  }}
                >
                  Trained
                </Dropdown.Item>
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    handleViewAction(row.original);
                  }}
                >
                  View
                </Dropdown.Item>
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        }
      },
      meta: {
        headerProps: { style: { width: '10%', textAlign: 'end' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  // Use React Query's useQuery with the filters as a dependency
  const {
    data: dataSource,
    isLoading,
    error
  } = useQuery({
    queryFn: () => fetchData({ trained_status: trainedStatus }),
    queryKey: ['clientChatSession', { trainedStatus }]
  });

  const { mutateAsync } = useMutation(
    {
      mutationFn: updateTrainedStatus,
      onSuccess: () => {
        // Invalidate and refetch the user list
        queryClient.invalidateQueries({
          queryKey: ['clientChatSession']
        });
      }
    },
    queryClient
  );

  const handleUpdate = (
    client_chat_session_id: number,
    trained_status: string,
    trained_chatbot_answer: string
  ) => {
    mutateAsync({
      client_chat_session_id,
      trained_status,
      trained_chatbot_answer
    });
  };
  const table = useAdvanceTable({
    data: dataSource,
    columns: columns,
    pageSize: 10,
    pagination: true,
    selection: false,
    sortable: true
  });

  if (isLoading) return <div>Loading data...</div>;
  if (error) return <div>Error fetching data</div>;

  return (
    <>
      <div className="text-right mb-7">
        <h3 className="text-body-highlight">
          Conversation between user and chatbot
        </h3>
      </div>

      <Row className="p-2">
        <Col md={4} className="p-2">
          <Form.Select
            aria-label="Default select example"
            onChange={e => {
              console.log('e.target.value', e.target.value);
              setTrainedStatus(e.target.value);
            }}
            defaultValue={'pending'}
          >
            <option value="all">all</option>
            <option value="pending">pending</option>
            <option value="on_training">on training</option>
            <option value="trained">trained</option>
            <option value="skipped">skipped</option>
          </Form.Select>
        </Col>
      </Row>
      <AdvanceTableProvider {...table}>
        {/* <SearchBox
                    placeholder="Search..."
                    size="sm"
                    onChange={handleSearchInputChange}
                    className="mx-auto mb-4"
                /> */}
        <AdvanceTable
          tableProps={{
            size: 'sm',
            className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
          }}
          rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
        />
        <AdvanceTableFooter navBtn />
      </AdvanceTableProvider>

      {/** VIEW */}
      <Offcanvas
        show={showViewOffcanvas}
        onHide={handleViewOffcanvasClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>View Client Chatbot Msg:</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form noValidate method="POST">
            <Form.Group className="mb-3 text-start" controlId="answer">
              <Form.Label>Client's question</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Answer"
                  name="answer"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.client_msg}
                  disabled
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group
              className="mb-3 text-start"
              controlId="trained_chatbot_answer"
            >
              <Form.Label>Trained Chatbot Answer</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Trained Chatbot Answer"
                  name="trained_chatbot_answer"
                  aria-describedby="inputGroupPrepend"
                  value={selectedRowData?.trained_chatbot_answer}
                  disabled
                  required
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showUpdateOffcanvas}
        onHide={handleUpdateOffcanvasClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Update Responce for Open AI</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleUpdateSubmit}
            method="POST"
          >
            <Form.Group className="mb-3 text-start" controlId="trained_status">
              <Form.Label>Trained Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="trained_status"
                onChange={handleChange}
              >
                <option>Open this select menu</option>
                <option value="on_training">On Training</option>
                <option value="skipped">Skipped</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              className="mb-3 text-start"
              controlId="trained_chatbot_answer"
            >
              <Form.Label>Trained Chatbot Answer</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Trained Chatbot Message"
                  name="trained_chatbot_answer"
                  aria-describedby="inputGroupPrepend"
                  value={formData.trained_chatbot_answer}
                  onChange={handleChange}
                  required
                />
              </InputGroup>
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ListClientChatSession;
