import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import CreateChatbotTableStructure from 'pages/dashboard/chatbot-table-structure/create';
import App from 'App';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import SignInPage from 'pages/sign-in';
import ListChatbotTableStructure from 'pages/dashboard/chatbot-table-structure/list';
import Error404 from 'pages/error/error404';
import Profile from 'pages/user/profile';
import { AuthRequiredProvider } from 'providers/AuthRequiredProvider';
import CreateInformationBase from 'pages/dashboard/information-base/create';
import ListInformationBaseStructure from 'pages/dashboard/information-base/list';
import ChangePassword from 'pages/user/change-password';
import CreateClient from 'pages/dashboard/client/create';
import ListClient from 'pages/dashboard/client/list';
import ListClientChatSession from 'pages/dashboard/client-chat-session/list';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: 'login',
        element: <SignInPage />
      },
      {
        path: '/',
        element: (
          <>
            <AuthRequiredProvider />
            <MainLayoutProvider>
              <MainLayout />
            </MainLayoutProvider>
          </>
        ),
        children: [
          {
            path: '/dashboard',
            children: [
              {
                path: 'chatbot-table-structure',
                children: [
                  {
                    path: 'list',
                    element: <ListChatbotTableStructure />
                  },
                  {
                    path: 'create',
                    element: <CreateChatbotTableStructure />
                  }
                ]
              },
              {
                path: 'information-base',
                children: [
                  {
                    path: 'list',
                    element: <ListInformationBaseStructure />
                  },
                  {
                    path: 'create',
                    element: <CreateInformationBase />
                  }
                ]
              },
              {
                path: 'client',
                children: [
                  {
                    path: 'list',
                    element: <ListClient />
                  },
                  {
                    path: 'create',
                    element: <CreateClient />
                  }
                ]
              },
              {
                path: 'client-chat-session',
                children: [
                  {
                    path: 'list',
                    element: <ListClientChatSession />
                  }
                ]
              }
            ]
          },
          {
            path: 'user/profile',
            element: <Profile />
          },
          {
            path: 'user/change-password',
            element: <ChangePassword />
          },
          {
            path: '*',
            element: <Error404 />
          }
        ]
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
