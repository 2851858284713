import { UilTimes } from '@iconscout/react-unicons';
import { CreateInformationBaseDto } from 'form/information-base/create.dto';
import helper from 'helpers/apiRequest';
import { useState } from 'react';
import { Button, Form, InputGroup, Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CreateInformationBase = () => {
  const navigate = useNavigate();
  const user_info = localStorage.getItem('user_info');
  let CLIENT_ID = 0;
  if (user_info) {
    const data = JSON.parse(user_info);
    CLIENT_ID = parseInt(data?.client_id);
  }
  const [showToast, setShowToast] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<CreateInformationBaseDto>({
    client_id: CLIENT_ID,
    answer: '',
    question: ''
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    console.log(form);
    const result = await helper.postRequest(
      '/client-information-base',
      formData
    ); // Adjust endpoint as needed
    if (result.message == 'create_client_information_base_successfully') {
      navigate('/dashboard/information-base/list');
    } else {
      setShowToast(true);
      setFormData({
        client_id: CLIENT_ID,
        answer: '',
        question: ''
      });
    }
    //logic for submit creating table structure info
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <>
      <div className="text-right mb-7">
        <h3 className="text-body-highlight">Create Information Base</h3>
      </div>

      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        method="POST"
      >
        <Form.Group className="mb-3 text-start" controlId="question">
          <Form.Label>Question</Form.Label>
          <InputGroup>
            <Form.Control
              as="textarea"
              placeholder="Question"
              name="question"
              aria-describedby="inputGroupPrepend"
              value={formData.question}
              onChange={handleChange}
              required
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3 text-start" controlId="answer">
          <Form.Label>Answer</Form.Label>
          <InputGroup>
            <Form.Control
              as="textarea"
              placeholder="Answer"
              name="answer"
              aria-describedby="inputGroupPrepend"
              value={formData.answer}
              onChange={handleChange}
              required
            />
          </InputGroup>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <div className="position-fixed center-0 center-0 p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <Button className="ms-2 p-0 " onClick={() => setShowToast(false)}>
              <UilTimes className="fs-7" />
            </Button>
          </Toast.Header>
          <Toast.Body>
            Failed to create the information base data in Database
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default CreateInformationBase;
